import React, { useState } from 'react';
import { bool } from 'prop-types';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  Avatar,
  Page,
  PaginationLinks,
  IconSpinner,
  Icons,
  Modal,
  Button,
  LayoutSingleColumn,
  UserNav,
  H3,
} from '../../components';
import TopbarContainer from '../../containers/TopbarContainer/TopbarContainer';
import { withRouter } from 'react-router-dom';
import {
  getOfferParams,
  getOfferPrice,
  getDisplayName,
  getTxImage,
  getTxTitle,
  isOwnOffer,
  hasOfferExpired,
  isArrayLength,
  allCounterCyclesCompleted,
} from '../../util/genericHelpers';
import moment from 'moment';
import { queryCurrentTxListing, updateOffer } from './OffersPage.duck';
import routeConfiguration from '../../routing/routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { initializeCardPaymentData } from '../../ducks/stripe.duck';
import { createSlug } from '../../util/urlHelpers';
import OfferForm from '../ListingPage/OfferForm/OfferForm';
import { draftId, draftSlug } from '../../routing/routeConfiguration';
import { getDefaultTimeZoneOnBrowser, timeOfDayFromLocalToTimeZone } from '../../util/dates';
import { withViewport } from '../../util/uiHelpers';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/ui.duck';
import { handleSubmit } from '../ListingPage/ListingPage.shared';
import { getOfferTransitions } from '../../transactions/transactionHelpers';
import { OFFER_CONSTANTS, OFFER_STATE_COLORS } from '../../util/enums';
import { useConfiguration } from '../../context/configurationContext';
import { formatMoney } from '../../util/currency';
import { getCurrencySymbol } from '../../config/settingsCurrency';

import css from './OffersPage.module.css';
import { isTransitionCounterByCustomer } from '../../transactions/transactionProcessPurchaseCash';

const timezone = getDefaultTimeZoneOnBrowser();

const {
  TRANSITION_SEND_OFFER,
  TRANSITION_ACCEPT_OFFER,
  TRANSITION_DECLINE_OFFER,
  TRANSITION_COUNTER_OFFER,
  TRANSITION_COUNTER_OFFER_BY_CUSTOMER,
  TRANSITION_ACCEPT_AFTER_COUNTER_OFFER,
  TRANSITION_ACCEPT_AFTER_COUNTER_OFFER_BY_PROVIDER,
  TRANSITION_DECLINE_AFTER_COUNTER_OFFER,
  TRANSITION_DECLINE_AFTER_COUNTER_OFFER_BY_PROVIDER,
} = getOfferTransitions();

const getExpirationMessage = (createdAt, intl) => {
  const expirationDate = moment(createdAt).add(48, 'hours');
  const timeRemaining = moment.duration(expirationDate.diff(moment())).asHours();
  const isExpired = timeRemaining <= 0;

  if (isExpired) {
    return { message: intl.formatMessage({ id: 'OffersPage.hasExpiredOffer' }), isExpired: true };
  } else {
    const numHours = Math.ceil(timeRemaining);
    const hourString = numHours === 1 ? 'hour' : 'hours';
    return {
      message: intl.formatMessage({ id: 'OffersPage.expirationTime' }, { numHours, hourString }),
      isExpired: false,
    };
  }
};

const getTitleMessage = (isCustomer, isExpired, price, displayName, intl) => {
  return isCustomer
    ? isExpired
      ? intl.formatMessage({ id: 'OffersPage.expiredOffer' }, { price })
      : intl.formatMessage({ id: 'OffersPage.offerValidity' }, { price })
    : intl.formatMessage({ id: 'OffersPage.offerReceived' }, { displayName, price });
};

export const getCurrentOfferState = (transitionType, transaction, currentUser, intl) => {
  const isCustomer = transaction?.customer?.id?.uuid === currentUser?.id?.uuid;
  const transactionCreatedAt = transaction?.attributes?.createdAt;
  const createdAt = timeOfDayFromLocalToTimeZone(transactionCreatedAt, timezone);
  const { message, isExpired } = getExpirationMessage(createdAt, intl);
  const price = getOfferPrice(transaction);
  const displayName = isCustomer
    ? getDisplayName(transaction?.provider)
    : getDisplayName(transaction?.customer);

  switch (transitionType) {
    case TRANSITION_SEND_OFFER:
      return {
        initial: true,
        message,
        status: OFFER_CONSTANTS.OFFERED,
        showActionBar: !isCustomer,
        showExploreMore: isExpired,
        color: OFFER_STATE_COLORS.OFFERED,
        title: getTitleMessage(isCustomer, isExpired, price, displayName, intl),
      };
    case TRANSITION_ACCEPT_OFFER:
      return {
        isAccepted: true,
        message: intl.formatMessage({ id: 'OffersPage.offerAccepted' }),
        status: OFFER_CONSTANTS.ACCEPTED,
        color: OFFER_STATE_COLORS.ACCEPTED,
        showBuyButton: isCustomer,
        title: intl.formatMessage(
          {
            id: isCustomer
              ? 'OffersPage.offerAcceptedCustomerTitle'
              : 'OffersPage.offerAcceptedProviderTitle',
          },
          { displayName, price }
        ),
        description: intl.formatMessage({
          id: isCustomer
            ? 'OffersPage.offerAcceptedCustomerDescription'
            : 'OffersPage.offerAcceptedProviderDescription',
        }),
      };
    case TRANSITION_DECLINE_OFFER:
      return {
        isDeclined: true,
        message: intl.formatMessage({ id: 'OffersPage.offerDeclined' }),
        status: OFFER_CONSTANTS.DECLINED,
        color: OFFER_STATE_COLORS.DECLINED,
        showExploreMore: true,
        title: intl.formatMessage(
          {
            id: isCustomer
              ? 'OffersPage.offerDeclinedCustomerTitle'
              : 'OffersPage.offerDeclinedProviderTitle',
          },
          { displayName, price }
        ),
      };
    case TRANSITION_COUNTER_OFFER:
      return {
        isCounter: true,
        message: intl.formatMessage({ id: 'OffersPage.counterOffer' }),
        status: OFFER_CONSTANTS.PENDING,
        color: OFFER_STATE_COLORS.OFFERED,
        showCounterActions: isCustomer,
        title: intl.formatMessage(
          {
            id: isCustomer
              ? 'OffersPage.counterOfferCustomerTitle'
              : 'OffersPage.counterOfferProviderTitle',
          },
          { displayName, price }
        ),
        description: intl.formatMessage(
          {
            id: isCustomer
              ? 'OffersPage.counterOfferCustomerDescription'
              : 'OffersPage.counterOfferProviderDescription',
          },
          { displayName }
        ),
      };
    case TRANSITION_COUNTER_OFFER_BY_CUSTOMER:
      return {
        isCounter: true,
        message: intl.formatMessage({ id: 'OffersPage.counterOffer' }),
        status: OFFER_CONSTANTS.PENDING,
        color: OFFER_STATE_COLORS.OFFERED,
        showActionBar: !isCustomer,
        showCounterActions: !isCustomer,
        title: intl.formatMessage(
          {
            id: !isCustomer
              ? 'OffersPage.counterOfferCustomerTitle'
              : 'OffersPage.counterOfferProviderTitle',
          },
          { displayName, price }
        ),
        description: intl.formatMessage(
          {
            id: !isCustomer
              ? 'OffersPage.counterOfferCustomerDescription'
              : 'OffersPage.counterOfferProviderDescription',
          },
          { displayName }
        ),
      };
    case TRANSITION_ACCEPT_AFTER_COUNTER_OFFER:
      return {
        isAcceptAfterCounter: true,
        message: intl.formatMessage({ id: 'OffersPage.counterOfferAccepted' }),
        status: OFFER_CONSTANTS.ACCEPTED,
        color: OFFER_STATE_COLORS.ACCEPTED,
        showBuyButton: isCustomer,
        title: intl.formatMessage(
          {
            id: isCustomer
              ? 'OffersPage.acceptCounterOfferCustomerTitle'
              : 'OffersPage.acceptCounterOfferProviderTitle',
          },
          { displayName, price }
        ),
        description: intl.formatMessage(
          {
            id: isCustomer
              ? 'OffersPage.acceptCounterOfferCustomerDescription'
              : 'OffersPage.acceptCounterOfferProviderDescription',
          },
          { displayName, price }
        ),
      };
    case TRANSITION_ACCEPT_AFTER_COUNTER_OFFER_BY_PROVIDER:
      return {
        isAcceptAfterCounter: true,
        message: intl.formatMessage({ id: 'OffersPage.counterOfferAccepted' }),
        status: OFFER_CONSTANTS.ACCEPT_AFTER_COUNTER_OFFER_BY_PROVIDER,
        color: OFFER_STATE_COLORS.ACCEPTED,
        showCounterActions: !isCustomer,
        showBuyButton: isCustomer,
        title: intl.formatMessage(
          {
            id: !isCustomer
              ? 'OffersPage.acceptCounterOfferCustomerTitle'
              : 'OffersPage.acceptCounterOfferProviderTitle',
          },
          { displayName, price }
        ),
        description: intl.formatMessage(
          {
            id: !isCustomer
              ? 'OffersPage.acceptCounterOfferCustomerDescription'
              : 'OffersPage.acceptCounterOfferProviderDescription',
          },
          { displayName, price }
        ),
      };
    case TRANSITION_DECLINE_AFTER_COUNTER_OFFER:
      return {
        isDeclineAfterCounter: true,
        message: intl.formatMessage({ id: 'OffersPage.counterOfferDeclined' }),
        status: OFFER_CONSTANTS.DECLINED,
        color: OFFER_STATE_COLORS.DECLINED,
        showExploreMore: true,
        title: intl.formatMessage(
          {
            id: isCustomer
              ? 'OffersPage.declineCounterOfferCustomerTitle'
              : 'OffersPage.declineCounterOfferProviderTitle',
          },
          { displayName, price }
        ),
      };
    case TRANSITION_DECLINE_AFTER_COUNTER_OFFER_BY_PROVIDER:
      return {
        isDeclineAfterCounter: true,
        message: intl.formatMessage({ id: 'OffersPage.counterOfferDeclined' }),
        status: OFFER_CONSTANTS.DECLINE_AFTER_COUNTER_OFFER_BY_PROVIDER,
        color: OFFER_STATE_COLORS.DECLINED,
        showCounterActions: !isCustomer,
        title: intl.formatMessage(
          {
            id: !isCustomer
              ? 'OffersPage.declineCounterOfferCustomerTitle'
              : 'OffersPage.declineCounterOfferProviderTitle',
          },
          { displayName, price }
        ),
      };
    default:
      return { message: '', status: '' };
  }
};

export const OfferItem = props => {
  const { currentUser, tx, history, intl } = props;
  const { customer, provider, listing } = tx;
  const otherUser = currentUser?.id?.uuid === provider?.id?.uuid ? customer : provider;
  const transactionCreatedAt = tx?.attributes?.createdAt;

  const marketplaceCurrency = getCurrencySymbol(listing?.attributes?.price?.currency);

  const createdAt = timeOfDayFromLocalToTimeZone(transactionCreatedAt, timezone);

  const offerPrice = getOfferPrice(tx);
  const params = getCurrentOfferState(tx?.attributes?.lastTransition, tx, currentUser, intl);
  const userDisplayName = getDisplayName(provider);
  const listingImage = getTxImage(tx);
  const listingTitle = getTxTitle(tx);
  const hasExpired = hasOfferExpired(createdAt);

  const listingPrice = listing?.attributes?.price
    ? formatMoney(intl, listing?.attributes?.price)
    : 0;

  return (
    <>
      <td className={css.productCol}>
        <div className={css.product}>
          <div
            className={css.itemImg}
            onClick={() => {
              history.push(
                createResourceLocatorString(
                  'ListingPage',
                  routeConfiguration(),
                  {
                    id: listing?.id?.uuid || draftId,
                    slug: createSlug(listing?.attributes?.title || draftSlug),
                  },
                  {}
                )
              );
            }}
          >
            <img src={listingImage} alt={listing?.attributes?.title} />
          </div>
          <div className={css.itemInfo}>
            <h2>{listingTitle}</h2>
            <p className={classNames(css.price, css.listingPrice)}>{listingPrice}</p>
          </div>
        </div>
      </td>
      <td className={css.dateCol}>
        <div className={css.date}>
          <p className={css.offerPrice}>
            {intl.formatMessage(
              { id: 'OffersPage.offerPrice' },
              { offerPrice: Number(offerPrice) > 0 ? Number(offerPrice).toFixed(2) : 0 }
            )}
          </p>
          <div
            className={classNames(css.lastTransitionedAt)}
            style={{
              color: hasExpired ? OFFER_STATE_COLORS.EXPIRED : params?.color,
            }}
          >
            {hasExpired || params?.initial ? (
              <Icons name={hasExpired ? 'expiredClock' : 'clock'} />
            ) : null}{' '}
            {hasExpired
              ? intl.formatMessage({ id: 'OffersPage.hasExpiredOffer' })
              : params?.message}
          </div>
        </div>
      </td>
      <td className={css.priceCol}>
        <div className={css.price}>
          <span className={classNames(css.priceItem, css.listingPrice)}>{listingPrice}</span>
        </div>
      </td>
      <td className={css.priceCol}>
        <div className={css.price}>
          <span className={css.priceItem}>
            {marketplaceCurrency}
            {Number(offerPrice) > 0 ? Number(offerPrice).toFixed(2) : 0}
          </span>
        </div>
      </td>
      <td className={css.customerCol}>
        <div className={css.customer}>
          <div className={css.itemAvatar}>
            <Avatar user={provider} disableProfileLink />
          </div>
          <div className={css.itemInfo}>
            <div className={css.itemUsername}>{userDisplayName}</div>
          </div>
        </div>
      </td>
    </>
  );
};

OfferItem.propTypes = {
  intl: intlShape.isRequired,
};

export const OffersPageComponent = props => {
  const {
    currentUser,
    fetchInProgress,
    fetchOrdersOrSalesError,
    intl,
    pagination,
    params,
    scrollingDisabled,
    transactions,
    onManageDisableScrolling,
    onUpdateOffer,
    updateOfferInProgress,
    history,
    callSetInitialValues,
    onInitializeCardPaymentData,
    onQueryCurrentTxListing,
    getListing,
  } = props;

  const config = useConfiguration();

  const [openOfferModal, setOffersModal] = useState(false);
  const [counterToggle, setCounterToggle] = useState(false);
  const [currentTransaction, setCurrentTransaction] = useState(null);

  const toTxItem = tx => {
    return (
      <tr
        key={tx?.id?.uuid}
        className={css.listItem}
        onClick={() => {
          setOffersModal(true);
          setCurrentTransaction(tx);
          onQueryCurrentTxListing(tx?.listing?.id, config);
        }}
      >
        <OfferItem currentUser={currentUser} tx={tx} intl={intl} history={history} />
      </tr>
    );
  };

  const error = fetchOrdersOrSalesError ? (
    <p className={css.error}>
      <FormattedMessage id="OffersPage.fetchFailed" />
    </p>
  ) : null;

  const noResults =
    !fetchInProgress && transactions.length === 0 && !fetchOrdersOrSalesError ? (
      <tr className={classNames(css.listItem, css.noResults)}>
        <td colSpan="5">
          <div className={css.noDataFound} key="noResults">
            <Icons name="tabRibbon" />
            <FormattedMessage id="OffersPage.noSalesFound" />
          </div>
        </td>
      </tr>
    ) : null;

  const pagingLinks =
    transactions?.length && pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="OffersPage"
        pagePathParams={params}
        pagination={pagination}
      />
    ) : null;

  const renderTable = (
    <table className={css.orderDatatable}>
      <thead className={css.tableHead}>
        <tr>
          <th className={css.productHead}>
            {intl.formatMessage({ id: 'OffersPage.productLabel' })}
          </th>
          <th className={css.statusHead}>{intl.formatMessage({ id: 'OffersPage.statusLabel' })}</th>
          <th className={css.priceHead}>
            {intl.formatMessage({ id: 'OffersPage.listingPriceLabel' })}
          </th>
          <th className={css.priceHead}>
            {intl.formatMessage({ id: 'OffersPage.offerPriceLabel' })}
          </th>
          <th className={css.customerHead}>{intl.formatMessage({ id: 'OffersPage.userLabel' })}</th>
        </tr>
      </thead>
      <tbody className={css.itemList}>
        {!fetchInProgress ? (
          transactions.map(toTxItem)
        ) : (
          <tr className={css.listItem}>
            <td colSpan="5">
              <div className={css.listItemsLoading}>
                <IconSpinner />
              </div>
            </td>
          </tr>
        )}
        {noResults}
      </tbody>
    </table>
  );

  const isCustomer = isOwnOffer(currentTransaction?.customer, currentUser);

  const offerParams = getOfferParams(currentTransaction);
  const hideCounterAction = allCounterCyclesCompleted(currentTransaction);

  const onRedirectToCheckoutWithOfferPrice = async () => {
    const listing = currentTransaction?.listing;
    const author = currentTransaction?.provider;

    const listingParams = {
      id: listing.id.uuid,
      slug: createSlug(listing.attributes.title),
    };

    const commonParams = { params: listingParams, history, routes: routeConfiguration() };

    const listingCountryCode = listing?.attributes?.publicData?.countryCode;

    handleSubmit({
      ...commonParams,
      currentUser,
      callSetInitialValues,
      getListing,
      author,
      onInitializeCardPaymentData,
    })({
      quantity: 1,
      deliveryMethod: 'shipping',
      isOffers: true,
      recipientCountryOrder: listingCountryCode,
      transactionId: currentTransaction?.id,
      offerParams,
    });
  };

  const handleUpdateOffer = async (transition, message, amount) => {
    const { id: transactionId, price: offerPrice, guestPageData } = currentTransaction || {};

    const isAccept = transition === TRANSITION_ACCEPT_OFFER;
    const isAcceptAfterCounter = [
      TRANSITION_ACCEPT_AFTER_COUNTER_OFFER_BY_PROVIDER,
      TRANSITION_ACCEPT_AFTER_COUNTER_OFFER,
    ].includes(transition);

    const isDeclineAfterCounter = [
      TRANSITION_DECLINE_AFTER_COUNTER_OFFER,
      TRANSITION_DECLINE_AFTER_COUNTER_OFFER_BY_PROVIDER,
    ].includes(transition);

    const isDecline = transition === TRANSITION_DECLINE_OFFER;

    const isCounterOffer = [
      TRANSITION_COUNTER_OFFER_BY_CUSTOMER,
      TRANSITION_COUNTER_OFFER,
    ].includes(transition);

    const params = {
      id: transactionId,
      transition,
      message:
        message ||
        (isAccept || isAcceptAfterCounter
          ? intl.formatMessage({ id: 'OffersPage.acceptedOffer' }, { offerPrice })
          : isDecline || isDeclineAfterCounter
          ? intl.formatMessage({ id: 'OffersPage.declinedOffer' }, { offerPrice })
          : ''),
      offerParams: {
        ...offerParams,
        transactionId: transactionId?.uuid,
        type: isAccept
          ? OFFER_CONSTANTS.ACCEPT
          : isAcceptAfterCounter
          ? OFFER_CONSTANTS.ACCEPT_AFTER_COUNTER_OFFER
          : isDeclineAfterCounter
          ? OFFER_CONSTANTS.DECLINE_AFTER_COUNTER_OFFER
          : isDecline
          ? OFFER_CONSTANTS.DECLINED
          : isCounterOffer
          ? OFFER_CONSTANTS.COUNTER
          : '',
      },
    };

    if (isCounterOffer) {
      params.offerParams.offerPrice = amount;
    }

    const offer = await onUpdateOffer(params);

    if (transition === TRANSITION_ACCEPT_AFTER_COUNTER_OFFER) {
      await onQueryCurrentTxListing(currentTransaction?.listing?.id, config);
      onRedirectToCheckoutWithOfferPrice();
    } else if (offer) {
      setOffersModal(false);
      setCurrentTransaction(null);
      setCounterToggle(null);
    }
  };

  const offerStateData = currentTransaction
    ? getCurrentOfferState(
        currentTransaction?.attributes?.lastTransition,
        currentTransaction,
        currentUser,
        intl
      )
    : null;

  const showBuyButton = !!offerStateData?.showBuyButton;
  const showExploreMore = !!offerStateData?.showExploreMore;
  const showActionBar = !!offerStateData?.showActionBar;
  const showCounterActions = !!offerStateData?.showCounterActions;

  const transactionCreatedAt = currentTransaction?.attributes?.createdAt;
  const createdAt = timeOfDayFromLocalToTimeZone(transactionCreatedAt, timezone);
  const hasExpired = hasOfferExpired(createdAt);

  const renderOffersForm = transition => (
    <OfferForm
      isOffersPage={true}
      price={currentTransaction?.listing?.attributes?.price}
      sendButtonText={intl.formatMessage({ id: 'OffersPage.sendCounterOffer' })}
      isCounterOffer={!!counterToggle}
      onSubmit={values => {
        const price = values?.price;
        const amount = price?.amount / 100;
        handleUpdateOffer(
          transition,
          intl.formatMessage(
            { id: 'OffersPage.sendUpdatedCounterOffer' },
            {
              displayName: currentUser?.attributes?.profile?.displayName,
              amount,
            }
          ),
          amount
        );
      }}
    />
  );

  const isCounterOfferByCustomer = isTransitionCounterByCustomer(
    currentTransaction?.attributes?.lastTransition
  );

  const renderOwnData = (
    <div className={css.offerModalContent}>
      <div
        className={css.offerModalImg}
        onClick={() => {
          history.push(
            createResourceLocatorString(
              'ListingPage',
              routeConfiguration(),
              {
                id: currentTransaction?.listing?.id?.uuid || draftId,
                slug: createSlug(currentTransaction?.listing?.attributes?.title || draftSlug),
              },
              {}
            )
          );
        }}
      >
        <img src={getTxImage(currentTransaction)} alt={getTxTitle(currentTransaction)} />
      </div>
      <h2>
        {hasExpired
          ? intl.formatMessage(
              { id: 'OffersPage.acceptedOfferExpired' },
              { offerPrice: offerParams?.offerPrice }
            )
          : offerStateData?.title}
      </h2>
      {offerStateData?.description && !hasExpired ? <p>{offerStateData?.description}</p> : null}

      {hasExpired ? null : showBuyButton ? (
        <div className={css.offerButtons}>
          <Button className={css.submitBtn} onClick={onRedirectToCheckoutWithOfferPrice}>
            {intl.formatMessage({ id: 'OffersPage.buyNow' })}
          </Button>
        </div>
      ) : null}

      {showExploreMore ? (
        <div className={css.showMore}>
          <span> {intl.formatMessage({ id: 'OffersPage.exploreMore' })}</span>
          <div className={css.offerButtons}>
            <Button
              className={css.submitBtn}
              onClick={() =>
                history.push(
                  createResourceLocatorString('SearchPage', routeConfiguration(), {}, {})
                )
              }
            >
              {intl.formatMessage({ id: 'OffersPage.explore' })}
            </Button>
          </div>
        </div>
      ) : null}
      {showCounterActions ? (
        updateOfferInProgress ? (
          <IconSpinner />
        ) : hasExpired ? null : !counterToggle ? (
          <div className={css.offerButtons}>
            <Button
              className={css.submitBtn}
              onClick={() =>
                handleUpdateOffer(
                  isCounterOfferByCustomer
                    ? TRANSITION_ACCEPT_AFTER_COUNTER_OFFER_BY_PROVIDER
                    : TRANSITION_ACCEPT_AFTER_COUNTER_OFFER
                )
              }
            >
              {intl.formatMessage({ id: 'OffersPage.accept' })}
            </Button>
            <Button
              onClick={() =>
                handleUpdateOffer(
                  isCounterOfferByCustomer
                    ? TRANSITION_DECLINE_AFTER_COUNTER_OFFER_BY_PROVIDER
                    : TRANSITION_DECLINE_AFTER_COUNTER_OFFER
                )
              }
            >
              {intl.formatMessage({ id: 'OffersPage.decline' })}
            </Button>
            {!hideCounterAction && (
              <Button onClick={() => setCounterToggle(!counterToggle)}>
                {intl.formatMessage({ id: 'OffersPage.counter' })}
              </Button>
            )}
          </div>
        ) : null
      ) : null}
      {counterToggle ? (
        <div className={css.counter}>{renderOffersForm(TRANSITION_COUNTER_OFFER_BY_CUSTOMER)}</div>
      ) : null}
    </div>
  );

  const renderActions = (
    <div className={css.offerModalContent}>
      <div className={css.offerModalImg}>
        <img src={getTxImage(currentTransaction)} alt={getTxTitle(currentTransaction)} />
      </div>
      <h2>
        {hasExpired
          ? intl.formatMessage({ id: 'OffersPage.accept' }, { offerPrice: offerParams?.offerPrice })
          : offerStateData?.title}
      </h2>
      {hasExpired ? null : (
        <>
          {showActionBar ? (
            updateOfferInProgress ? (
              <IconSpinner />
            ) : !counterToggle ? (
              <div className={css.offerButtons}>
                <Button
                  onClick={() =>
                    handleUpdateOffer(
                      isCounterOfferByCustomer
                        ? TRANSITION_ACCEPT_AFTER_COUNTER_OFFER_BY_PROVIDER
                        : TRANSITION_ACCEPT_OFFER
                    )
                  }
                >
                  {intl.formatMessage({ id: 'OffersPage.accept' })}
                </Button>
                <Button
                  onClick={() =>
                    handleUpdateOffer(
                      isCounterOfferByCustomer
                        ? TRANSITION_DECLINE_AFTER_COUNTER_OFFER_BY_PROVIDER
                        : TRANSITION_DECLINE_OFFER
                    )
                  }
                >
                  {intl.formatMessage({ id: 'OffersPage.decline' })}
                </Button>
                {!hideCounterAction && (
                  <Button onClick={() => setCounterToggle(!counterToggle)}>
                    {intl.formatMessage({ id: 'OffersPage.counter' })}
                  </Button>
                )}
              </div>
            ) : null
          ) : null}
          {counterToggle ? (
            <div className={css.counter}>{renderOffersForm(TRANSITION_COUNTER_OFFER)}</div>
          ) : null}
        </>
      )}
    </div>
  );

  const offerModal = (
    <Modal
      id="OffersPage.offerModal"
      isOpen={openOfferModal}
      onClose={() => {
        setOffersModal(false);
        setCurrentTransaction(null);
        setCounterToggle(false);
      }}
      usePortal
      onManageDisableScrolling={onManageDisableScrolling}
      isOfferModal={true}
    >
      {isCustomer ? renderOwnData : renderActions}
    </Modal>
  );

  return (
    <Page
      title={intl.formatMessage({ id: 'OffersPage.title' })}
      scrollingDisabled={scrollingDisabled}
    >
      <LayoutSingleColumn
        className={css.pageRoot}
        topbar={
          <>
            <TopbarContainer
              currentPage="OffersPage"
              desktopClassName={css.desktopTopbar}
              mobileClassName={css.mobileTopbar}
            />
            <UserNav currentPage="OffersPage" />
          </>
        }
      >
        {error}

        <div className={css.mainContent}>
          <div className={css.fixedWidthLayout}>
            <div className={css.payoutsContent}>
              {error}
              <H3 className={css.heading}>
                <FormattedMessage id="OffersPage.title" />
              </H3>
              {renderTable}
              {pagingLinks}
            </div>
          </div>
        </div>
        {offerModal}
      </LayoutSingleColumn>
    </Page>
  );
};

OffersPageComponent.defaultProps = {
  currentUser: null,
  fetchOrdersOrSalesError: null,
};

OffersPageComponent.propTypes = {
  currentUser: propTypes.currentUser,
  fetchInProgress: bool.isRequired,
  fetchOrdersOrSalesError: propTypes.error,
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    fetchInProgress,
    fetchOrdersOrSalesError,
    pagination,
    transactionRefs,
    updateOfferInProgress,
    updateOfferSuccess,
    updateOfferError,
  } = state.OffersPage;
  const { currentUser, currentUserHasListings } = state.user;

  const transactions = getMarketplaceEntities(state, transactionRefs);

  const filterOffers = transactions => {
    return transactions.filter(t => t?.attributes?.protectedData?.offerParams);
  };

  const filterValidOffers = offers => {
    const pastTimestamp = moment()
      .subtract(48, 'hours')
      .unix();
    return offers.filter(t => {
      const params = getOfferParams(t);
      const createdAt = params?.createdAt;
      return createdAt >= pastTimestamp;
    });
  };

  const onlyOffers = isArrayLength(transactions) ? filterOffers(transactions) : [];
  const onlyValidOffers = isArrayLength(onlyOffers) ? filterValidOffers(onlyOffers) : [];

  // Update the properties with new values
  const newOnlyValidOffersLength = onlyValidOffers?.length || 0; // New length of the valid offers

  // Calculate the new total pages based on the new length of valid offers
  const newTotalPages = Math.ceil(newOnlyValidOffersLength / pagination?.perPage);

  // Create a new object with the updated properties
  const updatedPaginationData = Object.assign({}, pagination, {
    totalItems: newOnlyValidOffersLength,
    totalPages: newTotalPages,
  });

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    currentUser,
    fetchInProgress,
    fetchOrdersOrSalesError,
    getListing,
    pagination: updatedPaginationData,
    scrollingDisabled: isScrollingDisabled(state),
    currentUserHasListings,
    transactions: onlyValidOffers,
    updateOfferInProgress,
    updateOfferSuccess,
    updateOfferError,
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  onUpdateOffer: params => dispatch(updateOffer(params)),
  onQueryCurrentTxListing: (listingId, config) =>
    dispatch(queryCurrentTxListing(listingId, config)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
});

const OffersPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withRouter,
  withViewport
)(OffersPageComponent);

export default OffersPage;
